import React from 'react'

import Layout from '../../components/Layout'
import UserManualsNavBar from '../../components/UserManualsNavBar'

const metaData = [
  {
    name: 'description',
    content: 'User manuals des',
  },
  {
    name: 'keywords',
    content:
      'perdicure chair, nail, nail furniture, ask us, nail salon furniture, nail chair',
  },
  { name: 'author', content: 'Contego Spa Design' },
]

const IndexPage = () => (
  <Layout
    title="User Manuals | Contego Spa Designs | Pedicure Spa Chair"
    metaData={metaData}
  >
    <UserManualsNavBar />
  </Layout>
)

export default IndexPage
